import React from "react"
import { Link } from "gatsby"

const JoinAccuIndex = ({
  why_boxes = [],
  why_description = {},
  why_text = {},
}) => {
  // console.log("================= ", why_boxes)
  return (
    <>
      <section className="pad50">
        <div className="container">
          <div className="row text-center">
            <div className="col-lg-8 whyContent">
              <h3 style={{ color: "#156eb6" }}>{why_text[0] !== undefined && why_text[0].text}</h3>
              <p className="description">{why_description[0] !== undefined && why_description[0].text}</p>
            </div>
          </div>
          <div className="row">
            {why_boxes.map((obj, whyIndex) => (
              <div
                key={"sdf" + whyIndex}
                className="col-lg-4 col-md-6 col-sm-6"
              >
                <div className="whyContentBox text-center">
                  {obj.why_is_external === false &&
                      <Link to={obj.why_link[0].text} >
                        <span className="icon-rounded">
                            <img src={obj.icon.url} alt="rounded"/>
                          </span>
                      </Link>
                    }
                    {obj.why_is_external === true &&
                      <a target="_blank" href={obj.why_link[0].text}>
                        <span className="icon-rounded">
                            <img src={obj.icon.url} alt="rounded"/>
                        </span>
                      </a>
                    }
                  <h6>{obj.why_title[0].text}</h6>
                  <p>{obj.why_description1[0].text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default JoinAccuIndex
