import React from "react"
import { PrismicRichText } from "./../../components/shared"

const JoinAccuIndex = ({
  demo_account_link = {},
  demo_account_icon = {},
  demo_account_icon_hover = {},
  join_heading = {},
  live_account_link = {},
  live_account_icon = {},
  live_account_icon_hover = {},
  text_below_demo_and_live_buttons = {},
}) => {
  // const [demoAccountIcon, setDemoAccountIcon] = useState(demo_account_icon.url)
  // const [liveAccountIcon, setLiveAccountIcon] = useState(live_account_icon.url)
  return (
    <>
      <section className="pad50">
        <div className="container">
          <div className="row text-center">
            <div className="col-lg-8 joinContent">
              <h3>{join_heading[0] !== undefined && join_heading[0].text}</h3>
              <div className="row">
                <div className="col-lg-6 tile">
                  <div
                    className="text-center tileBox"
                    // onMouseEnter={e =>
                    //   setDemoAccountIcon(demo_account_icon_hover.url)
                    // }
                    // onMouseLeave={e =>
                    //   setDemoAccountIcon(demo_account_icon.url)
                    // }
                  >
                    <a
                      href={demo_account_link.url}
                      className="btn btn-block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div>
                        {/* <img className="icon_normal" src={demo_account_icon.url} alt="Card Back" />
                          <img className="icon_hover" src={live_account_icon.url} alt="Card Back"  /> */}
                        <div
                          className="your-img"
                          style={{
                            color: "red",
                            backgroundImage: `url(${demo_account_icon.url})`,
                          }}
                        >
                          <div
                            className="your-hover"
                            style={{
                              backgroundImage: `url(${demo_account_icon_hover.url})`,
                            }}
                          ></div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 tile">
                  <div
                    className="text-center tileBox"
                    // onMouseEnter={e =>
                    //   setLiveAccountIcon(live_account_icon_hover.url)
                    // }
                    // onMouseLeave={e =>
                    //   setLiveAccountIcon(live_account_icon.url)
                    // }
                  >
                    <a
                      href={live_account_link.url}
                      className="btn btn-block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div>
                        {/* <img src={live_account_icon.url} alt="Card Back"/> */}
                        <div
                          className="your-img"
                          style={{
                            color: "red",
                            backgroundImage: `url(${live_account_icon_hover.url})`,
                          }}
                        >
                          <div
                            className="your-hover"
                            style={{
                              backgroundImage: `url(${live_account_icon.url})`,
                            }}
                          ></div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="description">
                <PrismicRichText data={text_below_demo_and_live_buttons} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default JoinAccuIndex
