import React from "react"
import { useSelector } from "react-redux"

import Layout from "../../components/layout"
import { HomePageQuery } from "../../prismic/staticQueries"

import JoinAccuIndex from "./join-accindex"
import WhyAccuIndex from "./why-accuindex"
import TradingPlatforms from "./trading-platforms"
import Offers from "./offers"
import PaymentGateways from "./payment-gateways"
import CarouselTop from "./carousel"
import { SubscriptionPanel } from "../../components/shared"

const IndexPage = () => {
  const language = useSelector(state => state.language)
  const { show_price_feed } = HomePageQuery(language)
  const homePageData = HomePageQuery(language)
  console.log(homePageData)
  return (
    <Layout>
      <>
        <CarouselTop {...homePageData} />
        {/* {show_price_feed === true && (
          <iframe
            title="iframe"
            src="https://fxpricing.com/fx-widget/ticker-tape-ask-bid-widget.php?id=1,13,39,18,20,1975,19,1984&widget_key=tgid5RkgVh3thZcZikbhVwFe&border=hide"
            style={{ height: "65px" }}
          ></iframe>
        )} */}
        <JoinAccuIndex {...homePageData} />
        <WhyAccuIndex {...homePageData} />
        <hr />
        <TradingPlatforms {...homePageData} />
        <hr />
        {/* <Offers {...homePageData} /> */}
        <PaymentGateways {...homePageData} />
        <SubscriptionPanel />
      </>
    </Layout>
  )
}

export default IndexPage
